var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"container-fluid"},[_c('PageTitle',{attrs:{"menu":'mail'}}),(_vm.isList)?[_c('b-overlay',{attrs:{"show":!_vm.dataList}},[_c('VTab',[_c('template',{slot:"link"},[_c('VTabLink',{attrs:{"step":_vm.tab,"option":"pesan-masuk"},on:{"update:step":function($event){_vm.tab=$event}}},[_vm._v("Pesan Masuk")]),_c('VTabLink',{attrs:{"step":_vm.tab,"option":"pesan-keluar"},on:{"update:step":function($event){_vm.tab=$event}}},[_vm._v("Pesan Keluar")])],1),_c('template',{slot:"content"},[_c('VTabContent',{attrs:{"step":_vm.tab,"option":_vm.tab},on:{"update:step":function($event){_vm.tab=$event}}},[_c('card-list',{attrs:{"filter":_vm.filter,"dataListing":_vm.data,"idKey":_vm.idKey,"fields":_vm.fields,"no-btn-mass":true,"no-multi-select":true},on:{"update:filter":function($event){_vm.filter=$event},"update:dataListing":function($event){_vm.data=$event},"update:data-listing":function($event){_vm.data=$event}},scopedSlots:_vm._u([{key:"tp_status",fn:function(data){return [_c('span',[_c('label',{staticClass:"label",class:data.scope.item[_vm.statusKey] === 1 && data.scope.item.tp_pesan_from === _vm.user.id_user_ghost ? 'label-success'
                        : data.scope.item[_vm.statusKey] === 2 ? 'label-info'
                        : data.scope.item[_vm.statusKey] === 1 && data.scope.item.tp_pesan_from !== _vm.user.id_user_ghost ? 'label-warning'
                        : ''},[_vm._v(" "+_vm._s(data.scope.item[_vm.statusKey] === 1 && data.scope.item.tp_pesan_from === _vm.user.id_user_ghost ? "Terkirim" : data.scope.item[_vm.statusKey] === 2 ? "Dibaca" : data.scope.item[_vm.statusKey] === 1 && data.scope.item.tp_pesan_from !== _vm.user.id_user_ghost ? "Belum Dibaca" : 'Terlaksana')+" ")])])]}},{key:"tp_message",fn:function(data){return [_c('span',{domProps:{"innerHTML":_vm._s(data.scope.value)}})]}},{key:"tp_create_date",fn:function(data){return [_vm._v(" "+_vm._s(_vm._f("moment")(data.scope.value,"DD-MM-YY, HH:mm:ss"))+" ")]}},{key:"aksi",fn:function(data){return [_c('b-button',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.detailMessage),expression:"detailMessage"}],staticClass:"mr-1",on:{"click":function($event){return _vm.doView(data.scope.item[_vm.idKey])}}},[_c('i',{staticClass:"ti-eye"})]),_c('b-button',{directives:[{name:"tooltip",rawName:"v-tooltip",value:('Hapus Pesan'),expression:"'Hapus Pesan'"}],staticClass:"bg-danger text-white",on:{"click":function($event){return _vm.doDelete(data.scope.index, data.scope.item, {query: {tab: _vm.$route.params.tab||'pesan-masuk'}})}}},[_c('i',{staticClass:"ti-trash"})])]}}],null,false,687848425)})],1)],1)],2)],1)]:_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('h5',{staticClass:"card-title mb-3"},[_vm._v(_vm._s(_vm.isAdd ? "Kirim": "Balas")+" Pesan")]),_c('ValidationObserver',{ref:"VForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.doSubmitCRUD)}}},[(_vm.isAdd)?_c('b-row',[_c('b-col',{attrs:{"md":"5"}},[_c('b-form-group',{scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Ditujukan Untuk "),_c('span',{staticClass:"text-danger mr5"},[_vm._v("*")])]},proxy:true}],null,true)},[_c('VSelect',{attrs:{"placeholder":"-- Pilih Penerima --","options":_vm.users},on:{"input":function($event){return _vm.setId($event)}},model:{value:(_vm.row.tp_select),callback:function ($$v) {_vm.$set(_vm.row, "tp_select", $$v)},expression:"row.tp_select"}}),_c('VValidate',{attrs:{"name":"Ditujukan","rules":{required: 1}},model:{value:(_vm.row.tp_select),callback:function ($$v) {_vm.$set(_vm.row, "tp_select", $$v)},expression:"row.tp_select"}})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Pesan "),_c('span',{staticClass:"text-danger mr5"},[_vm._v("*")])]},proxy:true}],null,true)},[_c('CKEditor',{attrs:{"value":_vm.row.tp_message,"customToolbar":_vm.itemsToolbar},on:{"update:value":function($event){return _vm.$set(_vm.row, "tp_message", $event)}}}),_c('VValidate',{attrs:{"name":"Pesan","rules":_vm.mrValidation.tp_message},model:{value:(_vm.row.tp_message),callback:function ($$v) {_vm.$set(_vm.row, "tp_message", $$v)},expression:"row.tp_message"}})],1)],1)],1):_vm._e(),_c('div',{staticClass:"row mt-4"},[_c('div',{staticClass:"col-md-2 offset-md-10"},[(_vm.isAdd)?_c('button',{staticClass:"btn btn-block btn-rounded  btn-info",attrs:{"type":"submit"}},[_vm._v("Kirim")]):_vm._e()])])],1)]}}])})],1)]),_c('b-modal',{attrs:{"id":"ModalDetail","title":'Detail Pesan',"size":"lg","ok-only":"","hide-footer":true,"no-close-on-backdrop":"","no-close-on-esc":"","scrollable":true}},[_c('div',{staticClass:"p-3"},[_c('ValidationObserver',{ref:"VForm2",scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.doReplyMessage)}}},[_vm._l((_vm.arrMessages),function(v,k){return _c('b-card',{key:k,staticClass:"shadow-none border"},[_c('div',{staticClass:"sender-profile mb-3"},[_c('b-img',{attrs:{"src":_vm.uploader(v.avatar),"rounded":"circle","width":"36","height":"36","blank":!v.avatar||v.avatar===null,"blank-color":"#777"}}),_c('div',{staticClass:"ml-3"},[_c('span',{staticClass:"d-block sender-name"},[_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(v.sender_name))])]),_c('div',{staticClass:"send-date"},[_vm._v(_vm._s(_vm._f("moment")(v.send_time,"lll")))])])],1),_c('span',{domProps:{"innerHTML":_vm._s(v.message)}})])}),(_vm.row.tp_pesan_to === _vm.user.id_user_ghost)?_c('b-form-group',{scopedSlots:_vm._u([(_vm.row.tp_balasan === null)?{key:"label",fn:function(){return [_c('span',{staticStyle:{"font-weight":"bold"}},[_vm._v("Balas Pesan ")]),_c('span',{staticClass:"text-danger mr5"},[_vm._v("*")])]},proxy:true}:null],null,true)},[(_vm.row.tp_balasan !== null)?_c('p',{staticClass:"mb-1",staticStyle:{"font-weight":"bold"}},[_vm._v("Pesan Balasan ("+_vm._s(_vm.row.replyer_name)+")")]):_vm._e(),_c('CKEditor',{attrs:{"value":_vm.row.tp_balasan_new,"customToolbar":_vm.itemsToolbar},on:{"update:value":function($event){return _vm.$set(_vm.row, "tp_balasan_new", $event)}}}),_c('VValidate',{attrs:{"name":"Pesan Balasan","rules":{required: 1}},model:{value:(_vm.row.tp_balasan_new),callback:function ($$v) {_vm.$set(_vm.row, "tp_balasan_new", $$v)},expression:"row.tp_balasan_new"}})],1):_vm._e(),(_vm.row.tp_pesan_to === _vm.user.id_user_ghost)?_c('div',{staticClass:"row mt-4"},[_c('div',{staticClass:"col-md-2 offset-md-10"},[_c('button',{staticClass:"btn btn-block btn-rounded  btn-info",attrs:{"type":"submit","disabled":_vm.submitButtonDisabled}},[_vm._v("Kirim")])])]):_vm._e()],2)]}}])})],1)])],2)])}
var staticRenderFns = []

export { render, staticRenderFns }