<template>
<div>
  <div class="container-fluid">
    <PageTitle :menu="'mail'" />
    <template v-if="isList">
      <b-overlay :show="!dataList">
        <VTab>
          <template slot="link">
            <VTabLink :step.sync="tab" option="pesan-masuk">Pesan Masuk</VTabLink>
            <VTabLink :step.sync="tab" option="pesan-keluar">Pesan Keluar</VTabLink>
          </template>
          <template slot="content">
            <VTabContent :step.sync="tab" :option="tab">
              <card-list 
                :filter.sync="filter"
                :dataListing.sync="data"
                :idKey="idKey"
                :fields="fields"

                :no-btn-mass="true"
                :no-multi-select="true"
              > 
                <template #tp_status="data">
                  <span>
                    <label 
                      class="label"
                      :class="data.scope.item[statusKey] === 1 && data.scope.item.tp_pesan_from === user.id_user_ghost ? 'label-success'
                        : data.scope.item[statusKey] === 2 ? 'label-info'
                        : data.scope.item[statusKey] === 1 && data.scope.item.tp_pesan_from !== user.id_user_ghost ? 'label-warning'
                        : ''
                      "
                    >
                      {{ 
                        data.scope.item[statusKey] === 1 && data.scope.item.tp_pesan_from === user.id_user_ghost ? "Terkirim"
                        : data.scope.item[statusKey] === 2 ? "Dibaca"
                        : data.scope.item[statusKey] === 1 && data.scope.item.tp_pesan_from !== user.id_user_ghost ? "Belum Dibaca"
                        : 'Terlaksana'
                      }}
                    </label>
                  </span>
                </template>
                <template #tp_message="data">
                  <span v-html="data.scope.value"></span>
                </template>
                <template #tp_create_date="data">
                  {{ data.scope.value | moment("DD-MM-YY, HH:mm:ss") }}
                </template>
                <template #aksi="data">
                  <!-- <b-button
                    v-if="
                      data.scope.item.sender_id === user.id_user_ghost && data.scope.item[statusKey] === 2
                    " 
                    class="mr-3" 
                    v-tooltip="data.scope.item.tp_balasan === null ? 'Balas Pesan' : 'Lihat Pesan'" :to="{name:$route.name, params:{pageSlug:data.scope.item[idKey]}}">
                    <i :class="data.scope.item.tp_balasan === null ? 'ti-share-alt' : 'ti-eye'"></i>
                  </b-button>
                  <span
                    v-else-if="
                        data.scope.item.sender_id != user.id_user_ghost
                  ">
                    <b-button
                      v-if="data.scope.item.tp_balasan === null"
                      class="mr-3" 
                      v-tooltip="'Balas Pesan'" :to="{name:$route.name, params:{pageSlug:data.scope.item[idKey]}}">
                      <i class="ti-share-alt"></i>
                    </b-button>
                    <b-button
                      v-else
                      class="mr-3" 
                      v-tooltip="'Lihat Pesan'" :to="{name:$route.name, params:{pageSlug:data.scope.item[idKey]}}">
                      <i class="ti-eye"></i>
                    </b-button>
                  </span> -->
                  <b-button class="mr-1" v-tooltip="detailMessage" @click="doView(data.scope.item[idKey])">
                    <i class="ti-eye"></i>
                  </b-button>
                  <b-button class="bg-danger text-white" v-tooltip="'Hapus Pesan'" @click="doDelete(data.scope.index, data.scope.item, {query: {tab: $route.params.tab||'pesan-masuk'}})">
                    <i class="ti-trash"></i>
                  </b-button>
                </template>
              </card-list>
            </VTabContent>
          </template>
        </VTab>
      </b-overlay>
    </template>
    <div 
      v-else 
      class="card"
    >
      <div class="card-body">
        <h5 class="card-title mb-3">{{isAdd ? "Kirim": "Balas"}} Pesan</h5>
        
        <ValidationObserver v-slot="{ handleSubmit }" ref="VForm">
          <form @submit.prevent="handleSubmit(doSubmitCRUD)">
            <b-row v-if="isAdd">
              <b-col md=5>
                <b-form-group>
                  <template #label>
                    Ditujukan Untuk <span class="text-danger mr5">*</span>
                  </template>
                  <VSelect
                    placeholder="-- Pilih Penerima --"
                    :options="users"
                    v-model="row.tp_select"
                    @input="setId($event)"
                  />
                  <VValidate 
                    name="Ditujukan" 
                    v-model="row.tp_select" 
                    :rules="{required: 1}" 
                  />
                </b-form-group>
              </b-col>
              <b-col md=12>
                <b-form-group>
                  <template #label>
                    Pesan <span class="text-danger mr5">*</span>
                  </template>
                  <CKEditor 
                    :value.sync="row.tp_message"
                    :customToolbar="itemsToolbar"
                  />
                  <VValidate 
                    name="Pesan" 
                    v-model="row.tp_message" 
                    :rules="mrValidation.tp_message" 
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <div class="row mt-4">
              <div class="col-md-2 offset-md-10">
                <!-- <button type="submit" class="btn btn-block btn-rounded  btn-info" v-if="!isAdd && row.tp_balasan === null">Kirim</button> -->
                <button type="submit" class="btn btn-block btn-rounded  btn-info" v-if="isAdd">Kirim</button>
              </div>
            </div>
          </form>
        </ValidationObserver>

      </div>
    </div>

    <b-modal 
      id="ModalDetail" 
      :title="'Detail Pesan'"
      size="lg"
      ok-only
      :hide-footer="true"
      no-close-on-backdrop
      no-close-on-esc
      :scrollable="true"
    >
      <div class="p-3">
        <ValidationObserver v-slot="{ handleSubmit }" ref="VForm2">
          <form @submit.prevent="handleSubmit(doReplyMessage)">
            <b-card class="shadow-none border" v-for="(v, k) in arrMessages" :key="k">
              <div class="sender-profile mb-3">
                <b-img :src="uploader(v.avatar)" rounded="circle" width="36" height="36" :blank="!v.avatar||v.avatar===null" blank-color="#777" />
                <div class="ml-3">
                  <span class="d-block sender-name"><span class="font-weight-bold">{{ v.sender_name }}</span></span>
                  <div class="send-date">{{ v.send_time | moment("lll") }}</div>
                </div>
              </div>
              <span v-html="v.message"></span>
            </b-card>
            <b-form-group v-if="row.tp_pesan_to === user.id_user_ghost">
              <template #label v-if="row.tp_balasan === null">
                <span style="font-weight: bold;">Balas Pesan </span><span class="text-danger mr5">*</span>
              </template>
              <p class="mb-1" style="font-weight: bold" v-if="row.tp_balasan !== null">Pesan Balasan ({{ row.replyer_name }})</p>
              <CKEditor 
                :value.sync="row.tp_balasan_new"
                :customToolbar="itemsToolbar"
              />
              <!-- <p v-else class="text-information" v-html="row.tp_balasan"></p> -->
              <VValidate 
                name="Pesan Balasan" 
                v-model="row.tp_balasan_new" 
                :rules="{required: 1}" 
              />
            </b-form-group>
            <div class="row mt-4" v-if="row.tp_pesan_to === user.id_user_ghost">
              <div class="col-md-2 offset-md-10">
                <button type="submit" class="btn btn-block btn-rounded  btn-info" :disabled="submitButtonDisabled">Kirim</button>
              </div>
            </div>
          </form>
        </ValidationObserver>
      </div>
    </b-modal>

  </div>
</div>
</template>
<style lang="scss" scoped>
  p.text-information{
    font-size: 15px;
  }
</style>
<style>
  .ck-editor__editable {
    min-height: 250px;
   }
</style>

<script>
import GlobalVue from '@libs/Global'
import PageTitle from '@view/include/PageTitle'
import Gen from '@libs/Gen'
import CardList from '@/components/CardList'
import CKEditor from '@/components/CKEditor'

import VTab from '@/components/VTab/VTab.vue'
import VTabLink from '@/components/VTab/VTabLink.vue'
import VTabContent from '@/components/VTab/VTabContent.vue'

let _ = global._
let $ = global.jQuery

export default{
  extends:GlobalVue,
  components:{PageTitle, CardList, CKEditor, VTab, VTabLink, VTabContent},
  data(){
    return {
      tab: false,
      idKey:'tp_id',
      statusKey: 'tp_status',
      fields:[
        { key: 'select', label: "" },
        { key:'number', label:'#' },
        { key:'sender_name', label:'Pengirim' },
        { key:'receiver_name', label:'Ditujukan' },
        { key:'tp_message', label:'Isi Pesan', is_custom: true },
        { key:'tp_status', label:'Status', is_custom:true, thClass: "width_120" },
        { key:'aksi', is_custom:true, thClass: "width_120" },
        { key:'tp_create_date', label: "Waktu Kirim", is_custom:true, thClass: "width_170" },
      ],
      users: [],
      itemsToolbar: [
        'bold',
        'italic',
        'underline',
        'strikethrough',
        'link',
        'bulletedList',
        'numberedList',
        '|',
        'undo',
        'redo'
      ],
      arrMessages: [],
      lastIdMessage: '',
      submitButtonDisabled: false,
    }
  },
  mounted(){
    this.row = {}
    this.apiGet({query: {tab: this.$route.params.tab||'pesan-masuk'}})
    this.$set(this, 'tab', this.$route.params.tab||'pesan-masuk')
  },
  computed: {
    detailMessage() {
      return this.tab == 'pesan-masuk' ? 'Lihat/Balas Pesan' : 'Lihat Pesan'
    }
  },
  methods: {
    setId(data){
      this.row["tp_pesan_to"] = data.bu_id
      // this.row["tp_to_level"] = data.bul_id
    },
    getMessages(message, arrMessage){
      if(!(message||{}).reply_message){
        return arrMessage
      }

      this.lastIdMessage = message.reply_message.m_id
      arrMessage.push({
        message: message.reply_message.message,
        sender_name: message.reply_message.sender_name,
        send_time: message.reply_message.send_time,
        avatar: message.reply_message.avatar
      })

      return this.getMessages((message||{}).reply_message, arrMessage)
    },
    doView(mail_id){
      this.loadingOverlay=true
      Gen.apiRest(
        "/do/"+this.modulePage,
        {
          data: {
            type: 'view-detail-mail',
            id: mail_id,
            user_id: this.user.id_user_ghost
          }
        },
        "POST"
      )
        .then(res => {
          if(res.data.row.tp_status === 2 || this.user.id_user_ghost === res.data.row.tp_pesan_to)
            this.apiGet({query: {tab: this.$route.params.tab||'pesan-masuk'}})
          this.loadingOverlay = false
          this.$bvModal.show('ModalDetail')
          this.$set(this, 'row', res.data.row)
        })
    },
    doReplyMessage(){
      const input = {
        ...this.row,
        last_id_message: this.lastIdMessage
      }

      this.submitButtonDisabled = true

       this.doSubmit(
         "/do/Mail",
        Object.assign(input, {
          type: 'update'
        }),
        (type) => {
          if(type === 'success'){
            this.apiGet({query: {tab: this.$route.params.tab||'pesan-masuk'}})
            this.$bvModal.hide('ModalDetail')
            this.submitButtonDisabled = false
          }
        },
        "POST", "VForm2"
      )
    },
    doSubmit(action, input, callback=false, method="POST", IDFORM='VForm2'){
      this.$refs[IDFORM].validate().then(success => {
        if (!success) { return; }
        
        if(this.loadingOverlay) return
        this.loadingOverlay = true 

        var data = $.parseJSON(JSON.stringify(input));
        var btnSubmit = $(".btn-loading");
        var prevHtml = btnSubmit.html();

        if($(".infoHTML").length){
          $(".infoHTML").removeClass('alert alert-danger alert-warning');
          $(".infoHTML").html('');
        }

        btnSubmit.html();
        Gen.apiRest(action, {data: $.extend({params:this.params}, data)}, method).then((res)=>{
            this.loadingOverlay = false
            let resp = res.data

            btnSubmit.html(prevHtml);

            resp.statusType = 200;
            this.doSetAlertForm(resp);
            
            if(callback){
              callback('success', resp)
            }

            setTimeout(()=>{ this.message="" },15000);
        }).catch((err)=>{
            this.loadingOverlay = false
            btnSubmit.html(prevHtml);
            
            if(err){
                err.statusType = err.status;
                err.status = "error";
                err.message = err.response?.data?.message;
                err.messageError = err.message
            }

            this.doSetAlertForm(err);
            if(this.failReset)
                this.$nextTick(() => {
                    this.$refs.form.reset();
                    this.message=""
                });
                
            if(callback){
              callback('error', err)
            }
        })
      });
    },
  },
  watch:{
    $route(){
      this.row = {}
      this.apiGet({query: {tab: this.$route.params.tab||'pesan-masuk'}})
      this.$set(this, 'tab', this.$route.params.tab||'pesan-masuk')
    },
    tab(v,o){
      if(!o || this.$route.params.pageSlug) return
      let q = _.clone(this.$route.query||{})
      this.$set(this, 'filter', {});
      q.tab = v
      window.location = this.$router.resolve({name:this.$route.name, params:{tab: q.tab}}).href;
    },
    "row.messages"(){
      if(!this.arrMessages.length){
        let rootMsg = _.clone(this.row.messages||{})
        delete rootMsg.reply_message
        rootMsg = [rootMsg]

        const msg = this.getMessages(_.clone(this.row.messages), rootMsg)
        this.$set(this, 'arrMessages', msg)
      }
    }
  },
}
</script>